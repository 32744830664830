import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

import axios from 'axios'
import { returnUrl } from '../config'
import { getItem, setItem } from '../api/index'

// import Login from '../Login.vue'
const Login = () => import('../Login.vue')
const Index = () => import('../views/Index.vue')
const Detail = () => import('../views/Detail.vue')
const Report = () => import('../views/Report.vue')
const Report1 = () => import('../views/Report1.vue')
const SampleData = () => import('../views/SampleData.vue')
const Setting = () => import('../views/Setting.vue')
const Service = () => import('../views/Service.vue')
const FeedBack = () => import('../components/service/FeedBack.vue')
const Course = () => import('../components/service/Course.vue')
const Overview = () => import('../views/Overview.vue')
const Login1 = () => import('../Login1.vue')
const Index1 = () => import('../views/Index1.vue')
const LoginNoLogo = () => import('../LoginNoLogo.vue')

const routes = [
  { path: '/', redirect: '/login' },
  // 主登录路由
  { path: '/login', component: Login },
  { path: '/loginNo', component: LoginNoLogo },
  {
    path: '/index',
    component: Index,
    children: [
      {
        path: 'overview',
        component: Overview
      },
      {
        name: 'detail',
        path: 'detail',
        component: Detail
      },
      {
        path: 'report',
        component: Report
      },
      {
        path: 'report1',
        component: Report1
      },
      {
        path: 'sampleData',
        component: SampleData
      },
      {
        path: 'setting',
        component: Setting
      },
      {
        path: 'service',
        component: Service,
        meta: { title: '客户服务' },
        children: [
          {
            path: 'feedback',
            component: FeedBack
          },
          {
            path: 'course',
            component: Course,
            meta: { title: '系统教程' }
          }
        ]
      },
      {
        path: 'test',
        name: 'test',
        component: () => import('../views/Test.vue')
      }
    ]
  },

  // 副登录路由
  { path: '/login1', component: Login1 },
  { path: '/index1', component: Index1 }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
let protocol = window.location.protocol
let urlObj = returnUrl(protocol)
// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  let title = getItem('title')
  let nextPath = '/login'
  if (title) {
    document.getElementsByTagName('title')[0].innerHTML = title
    nextPath = title == '冷丘钢丝绳测到位系统' ? '/login' : '/loginNo'
  } else {
    document.getElementsByTagName('title')[0].innerHTML = '冷丘钢丝绳测到位系统'
    nextPath = '/login'
  }
  if (to.path == '/loginNo') {
    setItem('title', '钢丝绳测到位系统')
    document.getElementsByTagName('title')[0].innerHTML = '钢丝绳测到位系统'
  }
  if (to.path == '/login') {
    setItem('title', '冷丘钢丝绳测到位系统')
    document.getElementsByTagName('title')[0].innerHTML = '冷丘钢丝绳测到位系统'
  }

  const getToken = window.sessionStorage.getItem('token')
  if (to.path === '/login' || to.path === '/login1' || to.path === '/loginNo') return next()
  if (!getToken) {
    if (!to.query.token) {

      return next(nextPath)
    } else {
      axios.defaults.headers = {
        'content-type': 'application/json',
        accesstoken: to.query.token
      }
      axios
        .request({
          url: urlObj.queryNoLoginUserInfor,
          method: 'get'
        })
        .then(res => {
          if (res.data && res.data.resultCode == '0000') {
            setItem('token', to.query.token)
            setItem('userData', res.data.data)
            next()
          } else {
            return next('/login')
          }
        })
      return
    }
  } else {
    next()
  }
  if (to.path === '/index' || to.path === '/index/service') return next('/index/overview')
  // next()
})
router.afterEach((to, from) => {
  if (from.path == '/login' && to.path != '/login') {
    window.location.reload()
  }
})
export default router

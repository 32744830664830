import { createStore } from "vuex";
import { mutations } from "./mutations";
import { actions } from "./actions";
const store = createStore({
  state: {
    encodeData: {
      direction: "",
      isMoving: "",
    },
    sensorData: {
      SerialAndID: "",
      result: "",
    },
  },
  mutations: {
    changeEncodeData(state, data) {
      state.encodeData = data;
    },
    changeSensorData(state, data) {
      state.sensorData = data;
    },
  },
  actions: actions,
});

export default store;
